import { defineStore } from 'pinia'
import { MarketingReportApi, ShopsApi } from '@/api/services'
import {
    GENDERS,
    GENDERS_TO_NUMBERS,
    LOGS_ITEMS_PER_PAGE,
    PERSONS_LOG_DISPLAY_MODES,
    REPORT_OUTPUT_FORMATS,
} from '@/constants/marketingReport'
import { stringToSearchRegex } from '@/utils/common'
import { getFormattedForRequestDate } from '@/utils/marketingReport/formatters'
import { logError } from '@/utils/common/index'

const localStorageKeys = {
    sortingDirection: 'persons_log_data_sort_date_old_to_new',
}

const getDefaultFilters = () => ({
    gender: { label: GENDERS.ALL, value: 'ALL' },
    time: {
        min: 0,
        max: 23 * 60 * 60 + 59 * 60 + 59,
    },
    camera: [],
    age: {
        min: 0,
        max: 100,
    },
    width: { min: 0, max: 500 },
    attributes: [],
    beardConfidence: 0.75,
})

const getDefaultState = () => ({
    isShowingSettings: false,
    isLoadingPersonsLogData: false,
    reportDataStartDate: null,
    marketingReportData: {},
    personsLogData: [],
    personsLogDataPage: 1,
    personsLogDataItemsPerPage: LOGS_ITEMS_PER_PAGE[1],
    personsLogDataTotalCount: 0,
    personsLogDisplayMode: PERSONS_LOG_DISPLAY_MODES.TABLE,
    personsLogDataFilters: getDefaultFilters(),
    personsLogDataCameras: [],
    personsLogDataCamerasSearchQuery: null,
    personsLogDataSortBy: null,
    personsLogDataSortNewToOld: !localStorage.getItem(
        localStorageKeys.sortingDirection,
    ),
})

export const useMarketingReportStore = defineStore('marketingReport', {
    state: getDefaultState,
    getters: {
        personsLogDataPagesMaxNumber(state) {
            return (
                Math.ceil(
                    state.personsLogDataTotalCount /
                        state.personsLogDataItemsPerPage,
                ) || 1
            )
        },
        filteredPersonsLogDataCameras(state) {
            if (!state.personsLogDataCamerasSearchQuery)
                return state.personsLogDataCameras

            return state.personsLogDataCameras.filter((camera) =>
                state.personsLogDataCamerasSearchQuery.test(camera),
            )
        },
    },
    actions: {
        resetReportData() {
            Object.assign(this, getDefaultState())
        },
        async fetchAvailableCameras({ storeToken }) {
            try {
                const { data } = await ShopsApi.getAvailableCameras({
                    storeToken,
                })

                this.personsLogDataCameras = data || []
            } catch (error) {
                logError(error)
            }
        },
        async fetchMarketingReport({ token, startDate }) {
            const formattedDate = getFormattedForRequestDate(startDate)

            const { data } = await MarketingReportApi.getReport({
                storeToken: token,
                startDate: formattedDate,
            })

            this.marketingReportData = data
        },
        getMarketingReportRequestData({ storeToken }) {
            return {
                storeToken,
                page: this.personsLogDataPage || 1,
                itemsPerPage: this.personsLogDataItemsPerPage,
                filters: {
                    gender: GENDERS_TO_NUMBERS[
                        this.personsLogDataFilters.gender.value
                    ],
                    camera: this.personsLogDataFilters.camera,
                    date: {
                        min: getFormattedForRequestDate(
                            this.reportDataStartDate,
                            this.personsLogDataFilters.time.min * 1000,
                        ),
                        max: getFormattedForRequestDate(
                            this.reportDataStartDate,
                            this.personsLogDataFilters.time.max * 1000,
                        ),
                    },
                    age: {
                        min: this.personsLogDataFilters.age.min,
                        max: this.personsLogDataFilters.age.max,
                    },
                    width: {
                        min: this.personsLogDataFilters.width.min,
                        max: this.personsLogDataFilters.width.max,
                    },
                    faceFilters: this.personsLogDataFilters.attributes.map(
                        (attribute) => attribute.value,
                    ),
                    beardConfidence:
                        this.personsLogDataFilters.beardConfidence,
                },
                sortBy: this.personsLogDataSortBy,
                desc: this.personsLogDataSortNewToOld,
            }
        },
        async fetchPersonsLogData({ storeToken }) {
            this.isLoadingPersonsLogData = true

            try {
                const {
                    data: { faces, totalCount },
                } = await MarketingReportApi.getPersonsLogData(
                    this.getMarketingReportRequestData({ storeToken }),
                )

                this.personsLogData = faces || []
                this.personsLogDataTotalCount = totalCount || 0
            } catch (error) {
                logError(error)
            } finally {
                this.isLoadingPersonsLogData = false
            }
        },
        async getPersonsLogDataCSV({ storeToken }) {
            try {
                const { data } = await MarketingReportApi.getPersonsLogData({
                    ...this.getMarketingReportRequestData({ storeToken }),
                    outputFormat: REPORT_OUTPUT_FORMATS.CSV,
                })

                return data
            } catch (error) {
                logError(error)
                return null
            }
        },
        updatePersonsLogDataPage(page) {
            if (page <= 0) return

            this.personsLogDataPage = page
        },
        updatePersonsLogDataItemsPerPage(itemsPerPage) {
            this.personsLogDataItemsPerPage = itemsPerPage
        },
        updateReportDataStartDate(startDate) {
            this.reportDataStartDate = startDate
        },
        updatePersonsLogDisplayMode(mode) {
            this.personsLogDisplayMode = mode
        },
        updatePersonsLogDataCamerasSearchQuery(query) {
            this.personsLogDataCamerasSearchQuery = stringToSearchRegex(query)
        },
        updatePersonsLogDataSortBy({ sortBy, descending }) {
            this.personsLogDataSortBy = sortBy
            this.personsLogDataSortNewToOld = descending

            if (descending) {
                localStorage.removeItem(localStorageKeys.sortingDirection)
            } else {
                localStorage.setItem(localStorageKeys.sortingDirection, true)
            }
        },
        resetFilters() {
            this.personsLogDataFilters = getDefaultFilters()
        },
        setPersonsLogDataFilterAge({ min, max }) {
            let newMin = min
            let newMax = max

            if (newMin === undefined) {
                newMin = this.personsLogDataFilters.age.min
            }

            if (newMax === undefined) {
                newMax = this.personsLogDataFilters.age.max
            }

            this.personsLogDataFilters.age = {
                min: newMin,
                max: newMax,
            }
        },
        setPersonsLogDataFilterGender(gender) {
            this.personsLogDataFilters.gender = gender
        },
        setPersonsLogDataFilterFaceWidth({ min, max }) {
            let newMin = min
            let newMax = max

            if (newMin === undefined) {
                newMin = this.personsLogDataFilters.width.min
            }

            if (newMax === undefined) {
                newMax = this.personsLogDataFilters.width.max
            }

            this.personsLogDataFilters.width = {
                min: newMin,
                max: newMax,
            }
        },
        setPersonsLogDataFilterCamera(camera) {
            this.personsLogDataFilters.camera = camera || undefined
        },
        setPersonsLogDataFilterAttributes(attributes) {
            this.personsLogDataFilters.attributes = attributes || []
        },
        setPersonsLogDataFilterBeardConfidence(beardConfidence) {
            this.personsLogDataFilters.beardConfidence =
                beardConfidence || undefined
        },
        setPersonsLogDataFilterTime({ min, max }) {
            let newMin = min
            let newMax = max

            if (newMin === undefined) {
                newMin = this.personsLogDataFilters.time.min
            }

            if (newMax === undefined) {
                newMax = this.personsLogDataFilters.time.max
            }

            this.personsLogDataFilters.time = {
                min: newMin,
                max: newMax,
            }
        },
        setIsShowingSettings(isShowing) {
            this.isShowingSettings = isShowing
        },
    },
})
