<template>
    <q-scroll-area visible>
        <q-form
            class="col-sm-3 col-xs-12 persons-logs-settings"
            @submit.prevent="onSubmitSettings"
            @keyup.enter="onSubmitSettings"
        >
            <input type="submit" hidden />
            <TheChartsSettings />
            <template v-if="isShowingLogsSettings">
                <div class="col q-mt-sm persons-logs-settings__histogram">
                    Время
                    <BaseHistogramSlider
                        class="persons-logs-settings__time"
                        :barsData="sliderBarsData"
                        :min="0"
                        :max="23 * 60 * 60 + 59 * 60 + 59"
                        :thumbsize="0"
                        :labelFormatter="timeFormatter"
                        :barColors="getActiveSliderBars"
                        :modelValue="personsLogDataFilters.time"
                        @update:modelValue="setPersonsLogDataFilterTime"
                    />
                    <div class="row q-col-gutter-x-sm q-mt-sm items-center">
                        <BaseTimePicker
                            dense
                            with-seconds
                            class="col"
                            :modelValue="
                                timeFormatter(personsLogDataFilters.time.min)
                            "
                            @update:modelValue="
                                (min) =>
                                    setPersonsLogDataFilterTime({
                                        min: timeStringToMinutes(min),
                                    })
                            "
                            :options="checkMaxTime"
                        />
                        <BaseTimePicker
                            dense
                            with-seconds
                            class="col"
                            :modelValue="
                                timeFormatter(personsLogDataFilters.time.max)
                            "
                            @update:modelValue="
                                (max) =>
                                    setPersonsLogDataFilterTime({
                                        max: timeStringToMinutes(max),
                                    })
                            "
                            :options="checkMinTime"
                        />
                    </div>
                </div>
                <div class="col q-mt-md persons-logs-settings__histogram">
                    Ширина лица
                    <BaseHistogramSlider
                        class="persons-logs-settings__time"
                        :barsData="widthBarChartData"
                        :min="widthBounds.min"
                        :max="widthBounds.max"
                        :thumbsize="0"
                        :labelFormatter="widthFormatter"
                        :barColors="getActiveWidthSliderBars"
                        :modelValue="personsLogDataFilters.width"
                        @update:modelValue="setPersonsLogDataFilterFaceWidth"
                    />
                    <div class="row q-col-gutter-x-sm q-mt-sm items-center">
                        <q-input
                            filled
                            class="col"
                            dense
                            :modelValue="personsLogDataFilters.width.min"
                            @update:modelValue="
                                (min) =>
                                    setPersonsLogDataFilterFaceWidth({
                                        min,
                                    })
                            "
                        />
                        <q-input
                            filled
                            class="col"
                            dense
                            :modelValue="personsLogDataFilters.width.max"
                            @update:modelValue="
                                (max) =>
                                    setPersonsLogDataFilterFaceWidth({
                                        max,
                                    })
                            "
                        />
                    </div>
                </div>
                <div class="col q-mt-md persons-logs-settings__histogram">
                    Возраст
                    <div>
                        <BaseRange
                            class="q-mt-sm"
                            :min="0"
                            :max="100"
                            :modelValue="personsLogDataFilters.age"
                            @update:modelValue="setPersonsLogDataFilterAge"
                        />
                    </div>
                    <div class="row q-col-gutter-x-sm q-mt-sm items-center">
                        <q-input
                            filled
                            class="col"
                            dense
                            :modelValue="personsLogDataFilters.age.min"
                            @update:modelValue="
                                (min) =>
                                    setPersonsLogDataFilterAge({
                                        min,
                                    })
                            "
                        />
                        <q-input
                            filled
                            class="col"
                            dense
                            :modelValue="personsLogDataFilters.age.max"
                            @update:modelValue="
                                (max) =>
                                    setPersonsLogDataFilterAge({
                                        max,
                                    })
                            "
                        />
                    </div>
                </div>
                <BaseSelect
                    class="col q-mt-md"
                    :options="genderOptions"
                    :modelValue="personsLogDataFilters.gender"
                    @update:modelValue="setPersonsLogDataFilterGender"
                    label="Пол"
                    use-input
                    hide-selected
                    fill-input
                    filled
                    dense
                />
                <BaseSelect
                    class="col q-mt-sm"
                    :modelValue="personsLogDataFilters.camera"
                    @update:modelValue="setPersonsLogDataFilterCamera"
                    :options="filteredPersonsLogDataCameras"
                    label="Камера"
                    use-input
                    fill-input
                    filled
                    dense
                    use-chips
                    stack-label
                    multiple
                    @filter="onFilterCameras"
                />
                <BaseSelect
                    class="col q-mt-sm"
                    :modelValue="personsLogDataFilters.attributes"
                    @update:modelValue="setPersonsLogDataFilterAttributes"
                    :options="attributesOptions"
                    label="Атрибуты"
                    use-input
                    fill-input
                    filled
                    dense
                    use-chips
                    stack-label
                    multiple
                />
                <BaseInput
                    v-if="isBeardSelected"
                    type="number"
                    class="col q-mt-sm"
                    label="Уверенность в бороде"
                    :modelValue="personsLogDataFilters.beardConfidence"
                    @update:modelValue="setPersonsLogDataFilterBeardConfidence"
                    dense
                    filled
                    clearable
                    min="0"
                    max="1"
                    step="0.01"
                />
            </template>
            <div>
                <BaseButton
                    class="q-mt-sm full-width"
                    label="Применить"
                    color="primary"
                    type="submit"
                />
                <BaseButton
                    class="q-mt-sm full-width"
                    label="Сбросить фильтры"
                    color="primary"
                    flat
                    @click="clearFilters"
                />
                <template v-if="isShowingLogsSettings">
                    <q-separator color="primary" class="q-my-md" />
                    <BaseButton
                        class="full-width"
                        label="Сохранить в Excel"
                        color="primary"
                        outline
                        @click="clickDownloadExcel"
                    />
                </template>
            </div>
        </q-form>
    </q-scroll-area>
</template>

<script>
import { useMarketingReportStore } from '@/stores/marketingReport'
import { storeToRefs } from 'pinia'
import { computed, watch } from 'vue'
import {
    GENDERS,
    ATTRIBUTES,
    PERSONS_LOG_DISPLAY_MODES,
    FACE_FILTERS,
} from '@/constants/marketingReport'
import { useShopsStore } from '@/stores/shops'
import {
    minutesToTimeString,
    timeStringToMinutes,
    usePersonsLogData,
} from '@/utils/marketingReport/personsLogData'
import TheChartsSettings from '@/components/TheChartsSettings'
import { useReportSettings } from '@/utils/marketingReport/reportSettings'
import { useChartsData } from '@/utils/marketingReport/chartsData'
import { checkIsTimeAfter } from '@/utils/common'

export default {
    name: 'ThePersonsLogsSettings',
    components: { TheChartsSettings },
    setup() {
        const marketingReportStore = useMarketingReportStore()
        const shopsStore = useShopsStore()

        const {
            personsLogData,
            personsLogDataPage,
            personsLogDataItemsPerPage,
            personsLogDataTotalCount,
            personsLogDataFilters,
            personsLogDataCameras,
            filteredPersonsLogDataCameras,
            personsLogDataSortBy,
            personsLogDataSortNewToOld,
            personsLogDisplayMode,
            reportDataStartDate,
            marketingReportData,
        } = storeToRefs(marketingReportStore)

        const {
            updatePersonsLogDataItemsPerPage,
            updatePersonsLogDataCamerasSearchQuery,
            setPersonsLogDataFilterAge,
            setPersonsLogDataFilterGender,
            setPersonsLogDataFilterFaceWidth,
            setPersonsLogDataFilterCamera,
            setPersonsLogDataFilterTime,
            setPersonsLogDataFilterAttributes,
            setPersonsLogDataFilterBeardConfidence,
            fetchPersonsLogData,
            getPersonsLogDataCSV,
            resetFilters,
        } = marketingReportStore

        const { shop, selectedStoreToken } = storeToRefs(shopsStore)

        const { hoursBarChartData, widthBarChartData } =
            useChartsData(marketingReportData)

        const isBeardSelected = computed(() => {
            return personsLogDataFilters.value.attributes.some(
                (attribute) =>
                    Number(attribute.value) === FACE_FILTERS.HasBeard,
            )
        })

        const sliderBarsData = computed(() => {
            const knownHoursData = hoursBarChartData.value
                ? hoursBarChartData.value.labels.map(Number)
                : []

            const hours = Array.from({ length: 24 }).map((_, i) => {
                const indexOfData = knownHoursData.indexOf(i)

                if (indexOfData !== -1) {
                    return {
                        label: i,
                        value: hoursBarChartData.value.datasets[0].data[
                            indexOfData
                        ],
                    }
                }

                return { label: i, value: 1 }
            })

            return hours
        })

        const getActiveSliderBars = ({ min, max, label }) => {
            return (
                label >= Math.floor(min / 3600) &&
                label <= Math.floor(max / 3600)
            )
        }

        const timeFormatter = (value) => {
            return minutesToTimeString(value)
        }

        const widthFormatter = (value) => Math.round(value)

        const getActiveWidthSliderBars = ({ min, max, label }) => {
            return label >= min && label <= max
        }

        const widthBounds = computed(() => {
            if (widthBarChartData.value.length === 0) {
                return { min: 0, max: 500 }
            }

            return {
                min: widthBarChartData.value[0].label,
                max:
                    widthBarChartData.value[widthBarChartData.value.length - 1]
                        .label + 10,
            }
        })

        const { downloadExcelData } = usePersonsLogData({
            personsLogData,
            shop,
        })

        const { onChangeSettings } = useReportSettings()

        const onSubmitSettings = () => {
            onChangeSettings({
                storeToken: selectedStoreToken.value,
                startDate: reportDataStartDate.value,
            })
        }

        watch(
            [
                personsLogDataPage,
                personsLogDataItemsPerPage,
                personsLogDataSortBy,
                personsLogDataSortNewToOld,
            ],
            () =>
                fetchPersonsLogData({ storeToken: selectedStoreToken.value }),
        )

        watch([widthBounds], () => {
            const minValue = Math.max(
                personsLogDataFilters.value.width.min,
                widthBounds.value.min,
            )

            const maxValue = Math.min(
                personsLogDataFilters.value.width.max,
                widthBounds.value.max,
            )

            const min = Math.min(minValue, maxValue)
            const max = Math.max(min, maxValue)

            const newFilter = {
                min,
                max,
            }
            setPersonsLogDataFilterFaceWidth(newFilter)
        })

        const checkMinTime = (endHour, endMinute, endSecond) => {
            const startTime = personsLogDataFilters.value.time.min

            return checkIsTimeAfter(
                {
                    hour: Math.floor(startTime / 3600),
                    minute: Math.floor((startTime % 3600) / 60),
                    second: startTime % 60,
                },
                { hour: endHour, minute: endMinute, second: endSecond },
            )
        }

        const checkMaxTime = (startHour, startMinute, startSecond) => {
            const endTime = personsLogDataFilters.value.time.max

            return checkIsTimeAfter(
                { hour: startHour, minute: startMinute, second: startSecond },
                {
                    hour: Math.floor(endTime / 3600),
                    minute: Math.floor((endTime % 3600) / 60),
                    second: endTime % 60,
                },
            )
        }

        const onFilterCameras = (e, update) => {
            updatePersonsLogDataCamerasSearchQuery(e)
            update()
        }

        const isShowingLogsSettings = computed(
            () =>
                personsLogDisplayMode.value !==
                    PERSONS_LOG_DISPLAY_MODES.GRAPHS &&
                personsLogDisplayMode.value !==
                    PERSONS_LOG_DISPLAY_MODES.DIAGNOSTICS,
        )

        const genderOptions = Object.entries(GENDERS).map(
            ([value, label]) => ({
                label,
                value,
            }),
        )

        const attributesOptions = Object.entries(ATTRIBUTES).map(
            ([value, label]) => ({
                label,
                value,
            }),
        )

        const clickDownloadExcel = async () => {
            const data = await getPersonsLogDataCSV({
                storeToken: selectedStoreToken.value,
            })

            if (!data) return

            downloadExcelData(data)
        }

        watch([shop], () => {
            resetFilters()
        })

        const clearFilters = () => {
            resetFilters()
            onSubmitSettings()
        }

        return {
            fetchPersonsLogData,
            updatePersonsLogDataItemsPerPage,
            personsLogDataPage,
            personsLogDataItemsPerPage,
            personsLogDataTotalCount,
            personsLogDataFilters,
            personsLogDataCameras,
            filteredPersonsLogDataCameras,
            setPersonsLogDataFilterAge,
            setPersonsLogDataFilterGender,
            setPersonsLogDataFilterFaceWidth,
            setPersonsLogDataFilterCamera,
            setPersonsLogDataFilterTime,
            setPersonsLogDataFilterAttributes,
            setPersonsLogDataFilterBeardConfidence,
            genderOptions,
            checkMinTime,
            checkMaxTime,
            onFilterCameras,
            onChangeSettings,
            isShowingLogsSettings,
            onSubmitSettings,
            clickDownloadExcel,
            sliderBarsData,
            timeFormatter,
            timeStringToMinutes,
            getActiveSliderBars,
            clearFilters,
            widthBarChartData,
            widthFormatter,
            getActiveWidthSliderBars,
            widthBounds,
            attributesOptions,
            isBeardSelected,
        }
    },
}
</script>

<style lang="scss" scoped>
.report {
    &__log {
        &-download {
            font-size: 0.75em;
            width: 100%;
        }
    }
}

.persons-logs-settings {
    width: $drawerWidth - $drawerPadding;
    padding: 0 $drawerPadding/1.5 0 $drawerPadding;
    overflow-x: hidden;

    &__time {
        width: $drawerWidth - $drawerPadding;
    }

    &__histogram {
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        padding: 8px 12px;
    }
}
</style>

<style lang="scss">
.persons-logs-settings {
    .q-slider__focus-ring {
        opacity: 0 !important;
    }
}
</style>
