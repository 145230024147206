<template>
    <BasePageLoader :isLoading="isFetchingData">
        <div class="fit q-py-lg report">
            <div class="col">
                <teleport to="body">
                    <div :class="overlayDrawerClass" @click="onClickOutside" />
                </teleport>
                <ThePersonsLogsSettings :class="settingsDrawerClass" />
                <ThePersonsLogData class="report__data" />
            </div>
        </div>
    </BasePageLoader>
</template>

<script>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useMarketingReportStore } from '@/stores/marketingReport'
import { useShopsStore } from '@/stores/shops'
import { useReportSettings } from '@/utils/marketingReport/reportSettings'
import ThePersonsLogsSettings from '@/components/ThePersonsLogsSettings'
import ThePersonsLogData from '@/components/ThePersonsLogData'
import { logError } from '@/utils/common'
import {
    GENDERS,
    LOGS_ITEMS_PER_PAGE,
    PERSONS_LOG_DISPLAY_MODES,
    ATTRIBUTES,
} from '@/constants/marketingReport'

export default {
    name: 'Report',
    components: {
        ThePersonsLogsSettings,
        ThePersonsLogData,
    },
    setup() {
        const route = useRoute()

        const marketingReportStore = useMarketingReportStore()
        const shopsStore = useShopsStore()

        const { shops } = storeToRefs(shopsStore)
        const { isShowingSettings } = storeToRefs(marketingReportStore)

        const { fetchShops, updateSelectedStoreToken } = shopsStore
        const {
            fetchPersonsLogData,
            updateReportDataStartDate,
            setIsShowingSettings,
            updatePersonsLogDataPage,
            updatePersonsLogDataItemsPerPage,
            updatePersonsLogDataSortBy,
            updatePersonsLogDisplayMode,
            setPersonsLogDataFilterAge,
            setPersonsLogDataFilterFaceWidth,
            setPersonsLogDataFilterGender,
            setPersonsLogDataFilterTime,
            setPersonsLogDataFilterCamera,
            setPersonsLogDataFilterAttributes,
            setPersonsLogDataFilterBeardConfidence,
            resetReportData,
        } = marketingReportStore

        const isFetchingData = ref(true)

        const {
            checkIsDateValid,
            resetStartDate,
            reportDataStartDate,
            selectedStoreToken,
            fetchNewMarketingData,
        } = useReportSettings()

        const settingsDrawerClass = computed(() => {
            return {
                report__settings: true,
                report__settings_active: isShowingSettings.value,
            }
        })

        const overlayDrawerClass = computed(() => {
            return {
                report__overlay: true,
                report__overlay_active: isShowingSettings.value,
            }
        })

        const onClickOutside = () => setIsShowingSettings(false)

        onBeforeMount(async () => {
            try {
                resetReportData()

                const {
                    startDate,
                    page,
                    itemsPerPage,
                    oldToNew,
                    tab,
                    ['age.min']: ageMin,
                    ['age.max']: ageMax,
                    ['width.min']: minWidth,
                    ['width.max']: maxWidth,
                    gender,
                    ['time.min']: startTime,
                    ['time.max']: endTime,
                    camera,
                    attributes,
                    beardConfidence,
                } = route.query

                updateSelectedStoreToken(route.params.token)
                updateReportDataStartDate(startDate)

                if (page) {
                    updatePersonsLogDataPage(Number(page))
                }

                if (
                    itemsPerPage &&
                    LOGS_ITEMS_PER_PAGE.includes(Number(itemsPerPage))
                ) {
                    updatePersonsLogDataItemsPerPage(Number(itemsPerPage))
                }

                if (oldToNew) {
                    updatePersonsLogDataSortBy({ descending: false })
                }

                if (
                    tab &&
                    Object.values(PERSONS_LOG_DISPLAY_MODES).includes(
                        Number(tab),
                    )
                ) {
                    updatePersonsLogDisplayMode(Number(tab))
                }

                const ageFilter = {
                    min: Number(ageMin),
                    max: Number(ageMax),
                }

                if (ageFilter.min >= 0 && ageFilter.max <= 100) {
                    setPersonsLogDataFilterAge(ageFilter)
                }

                const widthFilter = {
                    min: Number(minWidth),
                    max: Number(maxWidth),
                }

                if (widthFilter.min >= 0 && widthFilter.max <= 500) {
                    setPersonsLogDataFilterFaceWidth(widthFilter)
                }

                if (gender && GENDERS[gender]) {
                    setPersonsLogDataFilterGender({
                        value: gender,
                        label: GENDERS[gender],
                    })
                }

                if (startTime) {
                    setPersonsLogDataFilterTime({ min: Number(startTime) })
                }

                if (endTime) {
                    setPersonsLogDataFilterTime({ max: Number(endTime) })
                }

                if (camera) {
                    setPersonsLogDataFilterCamera(camera.split(','))
                }

                if (beardConfidence) {
                    setPersonsLogDataFilterBeardConfidence(
                        Number(beardConfidence),
                    )
                }

                if (attributes) {
                    setPersonsLogDataFilterAttributes(
                        attributes.split(',').map((value) => ({
                            label: ATTRIBUTES[value],
                            value: Number(value),
                        })),
                    )
                }

                if (!shops.value.length) {
                    await fetchShops()
                }

                if (!checkIsDateValid(reportDataStartDate.value)) {
                    resetStartDate()
                    return
                }

                fetchPersonsLogData({ storeToken: selectedStoreToken.value })

                fetchNewMarketingData({
                    token: selectedStoreToken.value,
                    startDate: reportDataStartDate.value,
                })
            } catch (error) {
                logError(error)
            } finally {
                isFetchingData.value = false
            }
        })

        return {
            isFetchingData,
            settingsDrawerClass,
            overlayDrawerClass,
            onClickOutside,
        }
    },
}
</script>

<style lang="scss" scoped>
.report {
    &__settings {
        position: fixed;
        width: $drawerWidth;
        height: calc(100vh - #{$toolbar-min-height} - #{$containerPadding});
        margin-left: $drawerPadding * -1;
        z-index: 1000;
        background: #ffffff;
        margin-top: map-get($space-lg, y) * -1;
        padding-top: map-get($space-lg, y);

        transition: all 0.5s;

        @media (max-width: $containerMaxWidth) {
            width: 0;
            height: calc(100vh - #{$toolbar-min-height});
            padding-bottom: map-get($space-lg, y);

            &_active {
                width: $drawerWidth;
            }
        }
    }

    &__data {
        padding-left: $drawerWidth - $drawerPadding/2 + 2px;

        @media (max-width: $containerMaxWidth) {
            padding-left: 0;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 900;
        background: $dimmed-background;
        opacity: 0;
        transition: all 0.5s;
        pointer-events: none;

        @media (max-width: $containerMaxWidth) {
            &_active {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}
</style>
